/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import MainButton from "../../components/Buttons/MainButton";
import { Notification } from "./notification";
import { useDispatch, useSelector } from "react-redux";
import ReactSpinner from "../../components/Loader/ReactSpinner";
import Pagination from "rc-pagination";
import "./index.css";
import {
  fetchNotifications,
  setNotificationsParams,
} from "../../store/notifications/actions";

const RootContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const TotalRowCount = styled.div`
  display: flex;
  justify-content: flex-end;
  color: #333;
  margin-top: 20px;
  padding-bottom: 20px;
  font-size: 16px;
  padding-right: 10px;
`;

const NotifContainer = styled.div`
  width: 100%;
  height: 80%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
`;

const PaginationContainer = styled.div`
  position: static;
  bottom: 20px;
`;
const Notifications = () => {
  const dispatch = useDispatch();
  const { data, params, loading } = useSelector((state) => state.notification);
  const { limit, offset } = params;
  const [currentPage, setCurrentPage] = useState(1);
  const getNotifications = async () => {
    await dispatch(fetchNotifications());
  };
  const handlePagination = async (index) => {
    setCurrentPage(index);
    dispatch(setNotificationsParams((index - 1) * limit));
  };
  useEffect(() => {
    getNotifications();
  }, [offset]);
  useEffect(() => {
    dispatch(setNotificationsParams(0));
  }, []);
  return (
    <RootContainer>
      <ReactSpinner loading={loading} />
      <HeaderWrapper bgColor="#f1f1f1">
        <h3 style={{ color: "black", marginLeft: "20px" }}>
          All Notifications
        </h3>
      </HeaderWrapper>

      <NotifContainer>
        {data?.data?.map((notif) => (
          <Notification key={notif.id} notif={notif} />
        ))}
      </NotifContainer>
      <PaginationContainer>
        <TotalRowCount>
          Showing {offset + 1} to {limit + offset} of {data?.total} entries
        </TotalRowCount>
        <Pagination
          total={data?.total}
          pageSize={limit}
          current={currentPage}
          onChange={(current) => {
            handlePagination(current);
          }}
          className="pagination_wrapper"
        />
      </PaginationContainer>
    </RootContainer>
  );
};

export default Notifications;

const HeaderWrapper = styled.div`
  background: ${({ bgColor }) => bgColor};
  width: 100%;
  height: 50px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  z-index: 20;
  margin-bottom: 20px;
`;
