import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import RouteWithLayout from "./components/RouteWithLayout";
import PublicLayout from "./components/Layouts/Public";
import LandingLayout from "./components/Layouts/Landing";
import MainLayout from "./components/Layouts/Main";
import Landing from "../src/pages/Landing";
import About from "../src/pages/About";
import Fields from "../src/pages/Fields";
import Resources from "../src/pages/Resources";
import SignIn from "../src/pages/SignIn";
import SignUp from "../src/pages/SignUp";
import EmailVerification from "../src/pages/EmailVerification";
import ResetPassword from "../src/pages/ResetPassword";
import Main from "../src/pages/Main";
import Names from "../src/pages/Names";
import Catalog from "../src/pages/Catalog";
import { io } from "socket.io-client";
import PageNotFound from "../src/pages/PageNotFound";
import WordList from "./pages/WordList";
import Settings from "./pages/settings/settings";
import ReactGA from "react-ga";
import { useDispatch } from "react-redux";
import { getNewNotif } from "./store/notifications/actions";
import Axios from "./utils/Axios";
import NotificationLayout from "./components/Layouts/Notification/index";
import Notifications from "./pages/Notifications/index";
import Terms from "./pages/Terms";

ReactGA.initialize("UA-215298632-1");

let socket;
function App() {
  const dispatch = useDispatch();

  const fetchUnseenNotifs = async (userId) => {
    await Axios.get(`/v1/getUserNotif`, {
      params: { userId },
    }).then((res) => {
      if (Array.isArray(res?.data) && res?.data.length) {
        const unSeenNotifs = res?.data?.filter((_) => _.seen === false);
        if (unSeenNotifs) dispatch(getNewNotif(true));
      }
    });
  };

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      // socket = io("http://localhost:3004");
      socket = io("//api.mayadatabase.org");
      socket.on("connect", () => {
        socket.emit(
          "joinNotifications",
          { sender: localStorage.getItem("userId") },
          () => {}
        );
        socket.on("recieveNotifications", () => {
          dispatch(getNewNotif(true));
        });
      });
      fetchUnseenNotifs(localStorage.getItem("userId"));
    }
  }, [localStorage.getItem("userId")]);
  return (
    <>
      <BrowserRouter>
        <Switch>
          <RouteWithLayout
            exact
            path="/"
            component={Landing}
            layout={LandingLayout}
          />

          <RouteWithLayout
            exact
            path="/about"
            component={About}
            layout={LandingLayout}
          />
          <RouteWithLayout
            exact
            path="/fields"
            component={Fields}
            layout={LandingLayout}
          />
          <RouteWithLayout
            exact
            path="/resources"
            component={Resources}
            layout={LandingLayout}
          />
          <RouteWithLayout
            exact
            path="/notifications"
            component={Notifications}
            layout={NotificationLayout}
          />
          {/* <RouteWithLayout path="/users" component={User} layout={MainLayout} />
        <RouteWithLayout path="/user-edit" component={UserEdit} layout={MainLayout} /> */}

          <RouteWithLayout path="/main" component={Main} layout={MainLayout} />
          <RouteWithLayout
            exact
            path="/settings"
            component={Settings}
            layout={LandingLayout}
          />

          <RouteWithLayout
            path="/main-names"
            component={Names}
            layout={MainLayout}
          />
          <RouteWithLayout
            path="/main-words"
            component={WordList}
            layout={MainLayout}
          />

          <RouteWithLayout
            path="/catalog"
            component={Catalog}
            layout={MainLayout}
          />

          <RouteWithLayout
            exact
            path="/login"
            component={SignIn}
            layout={LandingLayout}
          />
          <RouteWithLayout
            exact
            path="/signUp"
            component={SignUp}
            layout={LandingLayout}
          />
          <RouteWithLayout
            exact
            path="/email-verification"
            component={EmailVerification}
            layout={LandingLayout}
          />
          <RouteWithLayout
            exact
            path="/reset-password"
            component={ResetPassword}
            layout={LandingLayout}
          />
          <Route render={() => <Terms />} />
          {/* <RouteWithLayout
            exact
            path="/privacy_policy"
            component={Terms}
            layout={<div></div>}
          /> */}
          <RouteWithLayout
            path="/*"
            component={PageNotFound}
            layout={PublicLayout}
          />
        </Switch>
      </BrowserRouter>
    </>
  );
}
export { socket };
export default App;
