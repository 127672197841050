import React, { useState } from "react";
import logoImage from "../../assets/images/header/logo.svg";
import {} from "../../store/user/constants";
import { NotificationContainer } from "../../components/Notification";
import {
  RootWrapper,
  AuthCard,
  Logo,
  // CheckBox,
} from "../SignIn/styles";
import styled from "styled-components";

import SettingsMenu from "./SettingsNavbar";
import UpdateName from "./UpdateName";
import UpdatePassowrd from "./UpdatePassword";
const ContentWrapper = styled.div`
  padding: 10px 20px;
  flex: 1;
`;
export const MainDisplayTab = [
  {
    id: "username",
    title: "Update Username",
  },

  {
    id: "password",
    title: "Update Password",
  },
];
export default function Settings() {
  const [activeTab, setActiveTab] = useState(MainDisplayTab[0].id);
  const handleClickTab = (id) => {
    setActiveTab(id);
  };

  return (
    <RootWrapper>
      <AuthCard>
        <SettingsMenu
          menu={MainDisplayTab}
          activeTab={activeTab}
          bgColor="#f1f1f1"
          onClickTab={handleClickTab}
        />
        <Logo src={logoImage} />

        <ContentWrapper>
          {activeTab === "username" && <UpdateName />}
          {activeTab === "password" && <UpdatePassowrd />}
        </ContentWrapper>
      </AuthCard>

      <NotificationContainer />
    </RootWrapper>
  );
}
