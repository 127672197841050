import React from "react";
import styled from "styled-components";
import Header from "../Main/Header";

const RootWrapper = styled.div`
  height: 100vh;
  position: relative;
  background: #fafafa;
`;

const Container = styled.div`
  position: relative;
  padding-top: 88px;
  color: #fff;
  height: 95%;
  z-index: 15;
`;

const NotificationLayout = ({ children }) => {
  return (
    <RootWrapper>
      <Header />
      <Container>{children}</Container>
    </RootWrapper>
  );
};

export default NotificationLayout;
