import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import Alert from "../../components/Alert/index";
import LoadingButton from "../../components/Buttons/LoadingButton";
import { updateUser } from "../../store/user/actions";
import {
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
} from "../../store/user/constants";
import { Notification } from "../../components/Notification";
import {
  Form,
  InputWrapper,
  Input,
  AlertWrapper,
  // CheckBox,
  ButtonWrapper,
} from "../SignIn/styles";
const initState = {
  currentPassword: "",
  password: "",
  confirmPassword: "",
};

export const MainDisplayTab = [
  {
    id: "username",
    title: "Update Username",
  },

  {
    id: "password",
    title: "Update Password",
  },
];
export default function UpdatePassowrd() {
  const dispatch = useDispatch();
  const [formState, setFormState] = useState({
    ...initState,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleChangeInput = useCallback(
    (e) => setFormState({ ...formState, [e.target.name]: e.target.value }),
    [formState]
  );
  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);
    setErrorMessage("");
    setIsLoading(true);

    if (formState.password !== formState.confirmPassword) {
      setErrorMessage("Confirm password does not match");
      setIsLoading(false);
      setIsSubmitting(false);
      return;
    }

    const payload = {
      password: formState.password,
      currentPassword: formState.currentPassword,
      confirmPassword: formState.confirmPassword,
    };

    const updateUserResult = await dispatch(updateUser(payload));

    if (updateUserResult.type === UPDATE_USER_SUCCESS) {
      setErrorMessage("");
      setIsLoading(false);
      setIsSubmitting(false);
      setFormState({ ...formState, ...initState });
      Notification("success", "Password has been successfully changed");
      return;
    }

    if (updateUserResult.type === UPDATE_USER_FAILURE) {
      console.log(updateUserResult);
      const message = updateUserResult?.errors?.data?.message;
      setIsLoading(false);
      setIsSubmitting(false);
      setErrorMessage(message ?? "Failed to  change password!");
      setFormState({ ...formState, ...initState });
    }
  });

  return (
    <Form onSubmit={handleSubmit}>
      {/* <InputWrapper>
            <Input
              type="text"
              placeholder="Username"
              name="username"
              onChange={handleChangeInput}
              required
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              type="email"
              placeholder="Email"
              name="email"
              onChange={handleChangeInput}
              required
            />
          </InputWrapper>
          */}
      <InputWrapper>
        <Input
          type="password"
          value={formState.currentPassword}
          placeholder="Current Password"
          name="currentPassword"
          onChange={handleChangeInput}
          required
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          value={formState.password}
          type="password"
          placeholder="New Password"
          name="password"
          onChange={handleChangeInput}
          required
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          type="password"
          placeholder="Confirm New Password"
          name="confirmPassword"
          value={formState.confirmPassword}
          onChange={handleChangeInput}
          required
        />
      </InputWrapper>
      {errorMessage && (
        <AlertWrapper>
          <Alert bgColor="#b50c0c" textColor="#111111">
            {errorMessage}
          </Alert>
        </AlertWrapper>
      )}
      <ButtonWrapper>
        <LoadingButton
          disabled={
            isLoading ||
            !formState.confirmPassword ||
            !formState.password ||
            !formState.currentPassword
          }
          type="submit"
          color="#fff"
          bgColor="#000000"
          size="18px"
          padding="10px"
          isLoading={isLoading}
        >
          Confirm
        </LoadingButton>
      </ButtonWrapper>
    </Form>
  );
}
