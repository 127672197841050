import React from "react";
import moment from "moment";
import styled from "styled-components";

const RootWrapper = styled.div`
  display: flex;
  color: black;
  padding: 5px 10px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px solid #f1f1f1;
  align-items: center;
  height: 60px;
`;
const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
`;
const Title = styled.div`
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: 500;
  text-overflow: "ellipsis";
  overflow: "hidden";
  white-space: "normal";
`;
const Body = styled.div`
  font-size: 18px;
  color: #656565;
  text-overflow: "ellipsis";
  overflow: "hidden";
  white-space: "normal";
`;

export const Notification = ({ notif }) => {
  return (
    <RootWrapper>
      <BodyWrapper>
        <Title>{notif?.title}</Title>
        <Body>{notif?.body}</Body>
      </BodyWrapper>
      <p style={{ color: "gray", fontSize: "15px" }}>
        {moment(notif?.createdAt).format("lll")}
      </p>
    </RootWrapper>
  );
};
