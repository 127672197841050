/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import Logo from "../../../../assets/images/header/logo-black.svg";
import { landingMenu } from "../../../../configs/menuConfig";
import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { logout } from "../../../../store/app/actions";
import { LOGOUT } from "../../../../store/app/constants";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Badge from "@mui/material/Badge";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  fetchNotifications,
  getNewNotif,
  markNotifAsSeen,
} from "../../../../store/notifications/actions";
import Divider from "@mui/material/Divider";
import moment from "moment";
const RootWrapper = styled.div`
  background: #f3f3f3;
  width: 100%;
  height: 88px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 20;
`;

const LogoWrapper = styled.a``;

const LogoImage = styled.img`
  height: 72px;
  padding-left: 120px;
`;

const LandingMenuWrapper = styled.ul`
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-right: 120px;
`;

const MenuItemWrapper = styled.li`
  float: left;
  margin-left: 80px;
  color: rgba(0, 0, 0, 0.64);
  cursor: pointer;
`;

const SelectedMenuItemWrapper = styled.li`
  float: left;
  margin-left: 80px;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
`;

const MenuTitle = styled.span`
  font-size: 18px;
`;

const UserWrapper = styled(Dropdown.Toggle)`
  border-radius: 50%;
  padding: 5px;
  margin-left: 54px !important;
  cursor: pointer !important;
  ::after {
    content: none !important;
  }
`;

export default function LandingHeader() {
  const history = useHistory();
  const [menuItems, setMenuItems] = useState(landingMenu);

  const { newNotif, data } = useSelector((state) => state.notification);

  const dispatch = useDispatch();
  const handleLogout = () => {
    const result = dispatch(logout());
    if (result.type == LOGOUT) {
      history.push("/login");
    }
  };
  const handleClickMenu = (item) => () => {
    const updatedMenuItem = {
      id: item.id,
      title: item.title,
      isClick: !item.isClick,
      navLink: item.navLink,
    };

    const tempMenuItems = [];
    menuItems.forEach((item) => {
      if (item.id !== updatedMenuItem.id) {
        item.isClick = false;
        tempMenuItems.push(item);
      } else {
        item.isClick = true;
        tempMenuItems.push(item);
      }
    });
    setMenuItems(tempMenuItems);

    history.push(item.link);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    dispatch(getNewNotif(false));
    dispatch(fetchNotifications(10, 0));
  };
  const handleClose = () => {
    setAnchorEl(null);
    data?.data?.map((_) => {
      !_.seen && markNotifAsSeen(_?.id);
    });
  };

  const ITEM_HEIGHT = 50;

  return (
    <RootWrapper>
      <LogoWrapper href="/">
        <LogoImage src={Logo} />
      </LogoWrapper>
      <LandingMenuWrapper>
        {menuItems.map((menu) => (
          <Fragment key={menu.id}>
            {!menu.isClick ? (
              <MenuItemWrapper onClick={handleClickMenu(menu)}>
                <MenuTitle>{menu.title}</MenuTitle>
              </MenuItemWrapper>
            ) : (
              <SelectedMenuItemWrapper onClick={handleClickMenu(menu)}>
                <MenuTitle>{menu.title}</MenuTitle>
              </SelectedMenuItemWrapper>
            )}
          </Fragment>
        ))}
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls="long-menu"
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          style={{ marginLeft: "50px" }}
        >
          {newNotif ? (
            <Badge variant="dot" color="primary">
              <NotificationsNoneIcon />
            </Badge>
          ) : (
            <NotificationsNoneIcon />
          )}
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "25ch",
              paddingBottom: "0px",
            },
          }}
        >
          {data?.data?.map((option) => (
            <MenuItem
              key={option?.id}
              selected={option === "Pyxis"}
              onClick={handleClose}
              divider={true}
            >
              <Grid
                container
                justifyContent="space-between"
                style={{ fontWeight: !option.seen && "600" }}
              >
                <Grid
                  item
                  xs={8}
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "normal",
                  }}
                >
                  {option.title}
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{
                    fontSize: "13px",
                    color: "gray",
                    textAlign: "right",
                  }}
                >
                  {moment(option.createdAt).format("ll")}
                </Grid>
              </Grid>
            </MenuItem>
          ))}
          <MenuItem
            onClick={() => {
              history.push("/notifications");
            }}
            style={{
              color: "#4d77a3",
              fontWeight: "bold",
              backgroundColor: "#C1C1C1",
              justifyContent: "center",
              position: "relative",
              width: "100%",
              bottom: "0px",
            }}
          >
            View All Notifications
          </MenuItem>
        </Menu>
        <Dropdown>
          <UserWrapper variant="secondary" id="dropdown-basic">
            <AccountCircleIcon />
          </UserWrapper>

          <Dropdown.Menu>
            <Dropdown.Item href="/settings">Settings</Dropdown.Item>
            <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </LandingMenuWrapper>
    </RootWrapper>
  );
}
