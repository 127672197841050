import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import logoImage from "../../assets/images/header/logo.svg";
import LoadingButton from "../../components/Buttons/LoadingButton";
import Alert from "../../components/Alert/index";
import {
  forgotpassword,
  forgotpasswordrequest,
} from "../../store/user/actions";
import {
  FORGOT_PASSWORD_REQUEST_FAILURE,
  FORGOT_PASSWORD_REQUEST_SUCCESS,
} from "../../store/user/constants";
import {
  RootWrapper,
  AuthCard,
  Logo,
  CardTitle,
  Form,
  InputWrapper,
  Input,
  Link,
  AlertWrapper,
  ButtonWrapper,
  HelpTextWrapper,
} from "../SignIn/styles";

export default function ResetPassword() {
  const history = useHistory();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("id");
  console.log(token);

  const [formState, setFormState] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);
    setErrorMessage("");
    setIsLoading(true);

    const payload =
      token == null
        ? {
            email: formState.email,
          }
        : {
            token: token.toString(),
            newPassword: formState.password,
          };

    if (token == null) {
      const forgotpasswordResult = await dispatch(
        forgotpasswordrequest(payload)
      );

      if (forgotpasswordResult.type === FORGOT_PASSWORD_REQUEST_SUCCESS) {
        setErrorMessage("");
        history.push(`/login`);
      }

      if (forgotpasswordResult.type === FORGOT_PASSWORD_REQUEST_FAILURE) {
        console.log("FAILURE");
        const message = forgotpasswordResult?.errors?.data?.message;
        setIsLoading(false);
        setIsSubmitting(false);
        setErrorMessage(message ?? "Failed to Send Request!");
      }
    } else {
      if (formState.password != formState.confirmPassword) {
        setIsLoading(false);
        setIsSubmitting(false);
        setErrorMessage("The passwords don't matches");
        return;
      }

      const forgotpasswordResult = await dispatch(forgotpassword(payload));

      if (forgotpasswordResult.type === FORGOT_PASSWORD_REQUEST_SUCCESS) {
        setErrorMessage("");
        history.push(`/login`);
      }

      if (forgotpasswordResult.type === FORGOT_PASSWORD_REQUEST_FAILURE) {
        console.log("FAILURE");
        const message = forgotpasswordResult?.errors?.data?.message;
        setIsLoading(false);
        setIsSubmitting(false);
        setErrorMessage(message ?? "Failed to Change Password");
      }
    }
  });
  const handleChangeInput = useCallback(
    (e) => setFormState({ ...formState, [e.target.name]: e.target.value }),
    [formState]
  );
  return (
    <RootWrapper>
      {token == null && (
        <AuthCard height="400px">
          <Logo src={logoImage} />
          <CardTitle>Reset Password</CardTitle>
          <Form onSubmit={handleSubmit}>
            <InputWrapper>
              <Input
                type="email"
                placeholder="Email"
                name="email"
                required
                onChange={handleChangeInput}
              />
            </InputWrapper>
            {errorMessage && (
              <AlertWrapper>
                <Alert bgColor="#b50c0c" textColor="#111111">
                  {errorMessage}
                </Alert>
              </AlertWrapper>
            )}
            <ButtonWrapper>
              <LoadingButton
                color="#fff"
                bgColor="#000000"
                size="18px"
                padding="10px"
                LoadingButton
                isLoading={isLoading}
                type="submit"
              >
                Send Reset Link
              </LoadingButton>
            </ButtonWrapper>
            <HelpTextWrapper>
              <Link href="/login">Sign In</Link>
            </HelpTextWrapper>
          </Form>
        </AuthCard>
      )}
      {token != null && (
        <AuthCard>
          <Logo src={logoImage} />
          <CardTitle>Reset Password</CardTitle>
          <Form onSubmit={handleSubmit}>
            <InputWrapper>
              <Input
                type="password"
                placeholder="Password"
                name="password"
                onChange={handleChangeInput}
                required
              />
            </InputWrapper>
            <InputWrapper>
              <Input
                type="password"
                placeholder="Confirm Password"
                name="confirmPassword"
                onChange={handleChangeInput}
                required
              />
            </InputWrapper>
            {errorMessage && (
              <AlertWrapper>
                <Alert bgColor="#b50c0c" textColor="#111111">
                  {errorMessage}
                </Alert>
              </AlertWrapper>
            )}
            <ButtonWrapper>
              <LoadingButton
                color="#fff"
                bgColor="#000000"
                size="18px"
                padding="10px"
                LoadingButton
                isLoading={isLoading}
                type="submit"
              >
                {" "}
                {token == "" ? "Send Reset Link" : "Change password"}
              </LoadingButton>
            </ButtonWrapper>
            <HelpTextWrapper>
              <Link href="/login">Sign In</Link>
            </HelpTextWrapper>
          </Form>
        </AuthCard>
      )}
    </RootWrapper>
  );
}
