export const AUTHENTICATE_REQUEST = "AUTHENTICATE_REQUEST";
export const AUTHENTICATE_SUCCESS = "AUTHENTICATE_SUCCESS";
export const AUTHENTICATE_FAILURE = "AUTHENTICATE_FAILURE";
export const SET_IMPERSONATING = "SET_IMPERSONATING";
export const LOGOUT = "LOGOUT";
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const CLICK_DOWNLOAD = "CLICK_DOWNLOAD";
export const LANDING_NAV_MENU = "LANDING_NAV_MENU";
export const QUERY_MENU = "QUERY_MENU";
export const FETCH_ACCESS_TOKEN = "FETCH_ACCESS_TOKEN";
export const EMAIL_VERIFICATION_REQUEST = "EMAIL_VERIFICATION_REQUEST";
export const EMAIL_VERIFICATION_SUCCESS = "EMAIL_VERIFICATION_SUCCESS";
export const EMAIL_VERIFICATION_FAILURE = "EMAIL_VERIFICATION_FAILURE";
