import {
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
  GET_NEW_NOTIFICATION,
  SET_NOTIFICATIONS_PARAMS,
} from "./constants";

const initialState = {
  data: {},
  loading: false,
  params: { offset: 0, limit: 15 },
  errors: [],
  newNotif: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_REQUEST:
      return { ...state, loading: true };
    case SET_NOTIFICATIONS_PARAMS:
      return { ...state, params: { ...state.params, offset: action.payload } };
    case GET_NEW_NOTIFICATION:
      return { ...state, newNotif: action.payload };
    case FETCH_NOTIFICATIONS_SUCCESS:
      return { ...state, loading: false, data: action.payload, errors: [] };
    case FETCH_NOTIFICATIONS_FAILURE:
      return { ...state, loading: false, errors: action.errors };
    default:
      return state;
  }
};

export default reducer;
