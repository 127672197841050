import React, { useState, useEffect } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchAccessToken, setAuthenticated } from "../../store/app/actions";
import { FETCH_ACCESS_TOKEN } from "../../store/app/constants";
import ReactGA from "react-ga";

const RouteWithLayout = ({ component: Component, layout: Layout, ...rest }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);
  const path = location.pathname;

  const [isFetchToken, setIsFetchToken] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const result = await dispatch(fetchAccessToken());
      console.log(result);
      if (result.type == FETCH_ACCESS_TOKEN) {
        setIsFetchToken(true);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location]);

  // Fetch data that is used throughout the app
  useEffect(() => {
    if (isFetchToken) {
      const authenticate = async () => {
        if (!app?.authenticated) {
          if (app?.userToken?.access_token === "") {
            if (
              path !== "/signUp" &&
              path !== "/reset-password" &&
              path !== "/privacy_policy"
            ) {
              history.replace("/login");
            }
            return;
          }
          await dispatch(setAuthenticated());
        }
      };
      authenticate();
    }
  }, [isFetchToken]);

  return (
    <Route
      {...rest}
      render={(routeProps) => (
        <Layout {...routeProps}>
          <Component {...routeProps} />
        </Layout>
      )}
    />
  );
};

export default RouteWithLayout;
