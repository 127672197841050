import Axios from "../../utils/Axios";

import {
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
  GET_NEW_NOTIFICATION,
  SET_NOTIFICATIONS_PARAMS,
} from "./constants";

const fetchNotifRequedt = () => ({ type: FETCH_NOTIFICATIONS_REQUEST });
const fetchNotifSuccess = (payload) => ({
  type: FETCH_NOTIFICATIONS_SUCCESS,
  payload,
});
const fetchNotifFailure = (errors) => ({
  type: FETCH_NOTIFICATIONS_FAILURE,
  errors,
});
export const getNewNotif = (payload) => ({
  type: GET_NEW_NOTIFICATION,
  payload,
});
export const setNotificationsParams = (payload) => ({
  type: SET_NOTIFICATIONS_PARAMS,
  payload,
});

export const fetchNotifications =
  (limit, offset) => async (dispatch, getState) => {
    dispatch(fetchNotifRequedt());
    try {
      const userId = localStorage.getItem("userId");
      const response = await Axios.get(`/v1/getUserNotif`, {
        params: {
          userId,
          offset: offset || getState().notification.params.offset,
          limit: limit || 20,
        },
      });

      if (response.status === 200) {
        if (response.data.success) {
          return dispatch(fetchNotifSuccess(response.data));
        }
      }
      return dispatch(fetchNotifFailure(response.data.message));
    } catch (e) {
      return dispatch(fetchNotifFailure(e));
    }
  };

export const markNotifAsSeen = async (id) => {
  try {
    const response = await Axios.post(`/v1/seenNotif`, {
      id,
    });
    if (response.status === 200) return response.data;
  } catch (err) {
    console.log(err, "this is the error when marking notifications as seen");
  }
};
