import Axios from "../../utils/Axios";

import {
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  FORGOT_PASSWORD_REQUEST_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_REQUEST_SUCCESS,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
} from "./constants";

const fetchUserRequest = () => ({ type: FETCH_USER_REQUEST });
const fetchUserSuccess = (payload) => ({ type: FETCH_USER_SUCCESS, payload });
const fetchUserFailure = (errors) => ({ type: FETCH_USER_FAILURE, errors });

export const fetchUser = () => async (dispatch) => {
  dispatch(fetchUserRequest());
  try {
    const response = await Axios.get("/v1/me");
    if (response.status === 200) {
      return dispatch(fetchUserSuccess(response.data));
    }
    return dispatch(fetchUserFailure(response.data.message));
  } catch (e) {
    return dispatch(fetchUserFailure(e));
  }
};

const createUserRequest = () => ({ type: CREATE_USER_REQUEST });
const createUserSuccess = (payload) => ({ type: CREATE_USER_SUCCESS, payload });
const createUserFailure = (errors) => ({ type: CREATE_USER_FAILURE, errors });

export const createUser = (params) => async (dispatch) => {
  dispatch(createUserRequest());
  try {
    const response = await Axios.post("/v1/users", params);
    if (response.status === 200) {
      return dispatch(createUserSuccess(response.data));
    }
    return dispatch(createUserFailure(response.data.message));
  } catch (e) {
    return dispatch(createUserFailure(e));
  }
};
export const forgotPasswordRequest = (params) => async (dispatch) => {
  dispatch(createUserRequest());
  try {
    const response = await Axios.post("/v1/users", params);
    if (response.status === 200) {
      return dispatch(createUserSuccess(response.data));
    }
    return dispatch(createUserFailure(response.data.message));
  } catch (e) {
    return dispatch(createUserFailure(e));
  }
};

const updateUserRequest = () => ({ type: UPDATE_USER_REQUEST });
// eslint-disable-next-line no-unused-vars
const updateUserSuccess = (payload) => ({ type: UPDATE_USER_SUCCESS, payload });
const updateUserFailure = (errors) => ({ type: UPDATE_USER_FAILURE, errors });

export const updateUser = (params) => async (dispatch) => {
  dispatch(updateUserRequest());
  try {
    console.log(params, "this is the params when calling the api");
    const url = params?.userName?.length
      ? `/v1/changeUserName`
      : `/v1/update-password`;
    const response = await Axios.put(url, {
      ...params,
    });
    if (response.status === 200) {
      return dispatch(updateUserSuccess(response.data));
    }
    return dispatch(updateUserFailure(response.data));
  } catch (e) {
    return dispatch(updateUserFailure(e));
  }
};

const forgotpasswordRequest = () => ({ type: FORGOT_PASSWORD_REQUEST });
const forgotpasswordSuccess = (payload) => ({
  type: FORGOT_PASSWORD_REQUEST_SUCCESS,
  payload,
});
const forgotpasswordFailure = (errors) => ({
  type: FORGOT_PASSWORD_REQUEST_FAILURE,
  errors,
});

export const forgotpasswordrequest = (params) => async (dispatch) => {
  console.log(params);
  dispatch(forgotpasswordRequest());
  try {
    const response = await Axios.post("/v1/forgot_passowrd_request", params);
    if (response.status === 200) {
      return dispatch(forgotpasswordSuccess(response.data));
    }

    return dispatch(forgotpasswordFailure(response.data.message));
  } catch (e) {
    return dispatch(forgotpasswordFailure(e));
  }
};

export const forgotpassword = (params) => async (dispatch) => {
  console.log(params);
  dispatch(forgotpasswordRequest());
  try {
    const response = await Axios.post("/v1/forgot_password", params);
    if (response.status === 200) {
      return dispatch(forgotpasswordSuccess(response.data));
    }

    return dispatch(forgotpasswordFailure(response.data.message));
  } catch (e) {
    return dispatch(forgotpasswordFailure(e));
  }
};
