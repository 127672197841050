export const landingMenu = [
  {
    id: "about",
    title: "About",
    link: "/about",
    isClick: false,
  },
  {
    id: "fields",
    title: "Fields",
    link: "/fields",
    isClick: false,
  },
];
