import React from "react";

import "./index.css";
const Terms = () => {
  return (
    <div className="WordSection1">
      <p
        className="MsoNormal"
        style={{ marginBottom: "13.5pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "27.0pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          Privacy Policy for Maya Hieroglyphic Database
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "15.0pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          At Maya Hieroglyphic Database, accessible from www.mayadatabase.org,
          one of our main priorities is the privacy of our visitors. This
          Privacy Policy document contains types of information that is
          collected and recorded by Maya Hieroglyphic Database and how we use
          it.
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "15.0pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          If you have additional questions or require more information about our
          Privacy Policy, do not hesitate to contact us.
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "15.0pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          This Privacy Policy applies only to our online activities and is valid
          for visitors to our website with regards to the information that they
          shared and/or collect in the Maya Hieroglyphic Database. This policy
          is not applicable to any information collected offline or via channels
          other than this website.{" "}
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "13.5pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          Consent
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "15.0pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          By using our website, you hereby consent to our Privacy Policy and
          agree to its terms.
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "13.5pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          Information we collect
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "15.0pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          The personal information that you are asked to provide, and the
          reasons why you are asked to provide it, will be made clear to you at
          the point we ask you to provide your personal information.
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "15.0pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          If you contact us directly, we may receive additional information
          about you such as your name, email address, phone number, the contents
          of the message and/or attachments you may send us, and any other
          information you may choose to provide.
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "15.0pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          When you register for an Account, we may ask for your contact
          information, including items such as email address.
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "13.5pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          How we use your information
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "15.0pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          We use the information we collect in various ways, including to:
        </span>
      </p>

      <ul type="disc">
        <li
          className="MsoNormal"
          style={{ color: "#666666", background: "white" }}
        >
          <span style={{ fontSize: "10.5pt", fontFamily: "Helvetica" }}>
            Provide, operate, and maintain our website
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{ color: "#666666", background: "white" }}
        >
          <span style={{ fontSize: "10.5pt", fontFamily: "Helvetica" }}>
            Improve, personalize, and expand our website
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{ color: "#666666", background: "white" }}
        >
          <span style={{ fontSize: "10.5pt", fontFamily: "Helvetica" }}>
            Understand and analyze how you use our website
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{ color: "#666666", background: "white" }}
        >
          <span style={{ fontSize: "10.5pt", fontFamily: "Helvetica" }}>
            Develop new products, services, features, and functionality
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{ color: "#666666", background: "white" }}
        >
          <span style={{ fontSize: "10.5pt", fontFamily: "Helvetica" }}>
            Communicate with you, either directly or through one of our
            partners, including for customer service, to provide you with
            updates and other information relating to the website
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{ color: "#666666", background: "white" }}
        >
          <span style={{ fontSize: "10.5pt", fontFamily: "Helvetica" }}>
            Send you emails
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{ color: "#666666", background: "white" }}
        >
          <span style={{ fontSize: "10.5pt", fontFamily: "Helvetica" }}>
            Find and prevent fraud
          </span>
        </li>
      </ul>

      <p
        className="MsoNormal"
        style={{ marginBottom: "13.5pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          Log Files
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "15.0pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          Maya Hieroglyphic Database follows a standard procedure of using log
          files. These files log visitors when they visit websites. All hosting
          companies do this and a part of hosting services' analytics. The
          information collected by log files include internet protocol (IP)
          addresses, browser type, Internet Service Provider (ISP), date and
          time stamp, referring/exit pages, and possibly the number of clicks.
          These are not linked to any information that is personally
          identifiable. The purpose of the information is for analyzing trends,
          administering the site, tracking users' movement on the website, and
          gathering demographic information.
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "13.5pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          Cookies and Web Beacons
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "15.0pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          Like any other website, Maya Hieroglyphic Database uses 'cookies'.
          These cookies are used to store information including visitors'
          preferences, and the pages on the website that the visitor accessed or
          visited. The information is used to optimize the users' experience by
          customizing our web page content based on visitors' browser type
          and/or other information.
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "13.5pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          Third Party Privacy Policies
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "15.0pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          Maya Hieroglyphic Database's Privacy Policy does not apply to other
          websites. Thus, we are advising you to consult the respective Privacy
          Policies of these third-party servers for more detailed information.
          It may include their practices and instructions about how to opt-out
          of certain options.
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "15.0pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          You can choose to disable cookies through your individual browser
          options. To know more detailed information about cookie management
          with specific web browsers, it can be found at the browsers'
          respective websites.
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "13.5pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          CCPA Privacy Rights (Do Not Sell My Personal Information)
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "15.0pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          Under the CCPA, among other rights, California consumers have the
          right to:
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "15.0pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          Request that a business that collects a consumer's personal data
          disclose the categories and specific pieces of personal data that a
          business has collected about consumers.
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "15.0pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          Request that a business delete any personal data about the consumer
          that a business has collected.
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "15.0pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          Request that a business that sells a consumer's personal data, not
          sell the consumer's personal data.
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "15.0pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us.
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "13.5pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          GDPR Data Protection Rights
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "15.0pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          We would like to make sure you are fully aware of all of your data
          protection rights. Every user is entitled to the following:
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "15.0pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          The right to access – You have the right to request copies of your
          personal data. We may charge you a small fee for this service.
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "15.0pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          The right to rectification – You have the right to request that we
          correct any information you believe is inaccurate. You also have the
          right to request that we complete the information you believe is
          incomplete.
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "15.0pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          The right to erasure – You have the right to request that we erase
          your personal data, under certain conditions.
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "15.0pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          The right to restrict processing – You have the right to request that
          we restrict the processing of your personal data, under certain
          conditions.
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "15.0pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          The right to object to processing – You have the right to object to
          our processing of your personal data, under certain conditions.
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "15.0pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          The right to data portability – You have the right to request that we
          transfer the data that we have collected to another organization, or
          directly to you, under certain conditions.
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "15.0pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us.
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "13.5pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          Children's Information
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "15.0pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          Another part of our priority is adding protection for children while
          using the internet. We encourage parents and guardians to observe,
          participate in, and/or monitor and guide their online activity.
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "15.0pt", background: "white" }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Helvetica",
            color: "#666666",
          }}
        >
          Maya Hieroglyphic Database does not knowingly collect any Personal
          Identifiable Information from children under the age of 13. If you
          think that your child provided this kind of information on our
          website, we strongly encourage you to contact us immediately and we
          will do our best efforts to promptly remove such information from our
          records.
        </span>
      </p>

      <p className="MsoNormal">&nbsp;</p>
    </div>
  );
};

export default Terms;
