import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import Alert from "../../components/Alert/index";
import LoadingButton from "../../components/Buttons/LoadingButton";
import { updateUser } from "../../store/user/actions";
import {
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
} from "../../store/user/constants";
import { Notification } from "../../components/Notification";
import {
  Form,
  InputWrapper,
  Input,
  AlertWrapper,
  // CheckBox,
  ButtonWrapper,
} from "../SignIn/styles";
const initState = {
  userName: "",
};

export default function UpdateName() {
  const dispatch = useDispatch();
  const [formState, setFormState] = useState({
    ...initState,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleChangeInput = useCallback(
    (e) => setFormState({ ...formState, [e.target.name]: e.target.value }),
    [formState]
  );
  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);
    setErrorMessage("");
    setIsLoading(true);

    const payload = {
      userName: formState.userName,
      id: localStorage?.getItem("userId"),
    };

    const updateUserResult = await dispatch(updateUser(payload));

    if (updateUserResult.type === UPDATE_USER_SUCCESS) {
      setErrorMessage("");
      setIsLoading(false);
      setIsSubmitting(false);
      setFormState({ ...formState, ...initState });
      Notification("success", "Username has been successfully changed");
      return;
    }

    if (updateUserResult.type === UPDATE_USER_FAILURE) {
      const message = updateUserResult?.errors?.data?.message;
      setIsLoading(false);
      setIsSubmitting(false);
      setErrorMessage(message ?? "Failed to  change username!");
      setFormState({ ...formState, ...initState });
    }
  });

  return (
    <Form onSubmit={handleSubmit}>
      <InputWrapper>
        <Input
          type="text"
          value={formState.currentPassword}
          placeholder="Username"
          name="userName"
          onChange={handleChangeInput}
          required
        />
      </InputWrapper>

      {errorMessage && (
        <AlertWrapper>
          <Alert bgColor="#b50c0c" textColor="#111111">
            {errorMessage}
          </Alert>
        </AlertWrapper>
      )}
      <ButtonWrapper>
        <LoadingButton
          disabled={isLoading || !formState.userName}
          type="submit"
          color="#fff"
          bgColor="#000000"
          size="18px"
          padding="10px"
          isLoading={isLoading}
        >
          Confirm
        </LoadingButton>
      </ButtonWrapper>
    </Form>
  );
}
